import React from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import BlockContent from '@sanity/block-content-to-react';
import Img from 'gatsby-image';
import { above } from '../styles/MediaQueries';

const StyledPersonCard = styled.div`
  width: 100%;
  /* background-color: var(--brand); */
  margin-bottom: 4rem;
  ${above.medium`
		margin-bottom: 2rem;
	`}
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  img {
    border-radius: 50%;
  }

  .project-teaser__list {
    padding-left: 1em;
    font-family: var(--sans-serif);
    font-size: var(--font-button);
    line-height: 1.2;
  }

  h3 {
    color: var(--brand);
  }
`;

const Button = styled.button`
  background-color: var(--brand);
  padding: 1em;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export default function PersonCard({ people }) {
  // Open external links in Text Editor in new tab
  // https://www.sanity.io/guides/portable-text-internal-and-external-links
  const serializers = {
    marks: {
      link: ({ children, mark }) =>
        mark.blank ? (
          <a href={mark.href} target="_blank" rel="noreferrer">
            {children}
          </a>
        ) : (
          <a href={mark.href}>{children}</a>
        ),
    },
  };

  return (
    <>
      {people.map(person => (
        <div key={person.name}>
          <Modal
            // https://reactpatterns.com/#render-prop
            activator={({ setIsModalOpen }) => (
              <>
                <StyledPersonCard onClick={() => setIsModalOpen(true)}>
                  {person.photo.asset.fluid && (
                    <Img
                      className=""
                      fluid={{
                        ...person.photo.asset.fluid,
                        aspectRatio: 1 / 1,
                      }}
                      // style={maxWidth: "100%"}
                      durationFadeIn={400}
                      alt=""
                      style={{
                        width: '85%',
                        margin: '0 auto',
                      }}
                      imgStyle={{
                        filter: 'grayscale(100%)',
                        width: '100%',
                      }}
                    />
                  )}
                  {/* <img src={person.imgSrc} alt={`Foto: ${person.name}`} /> */}
                  <h3>{person.name}</h3>
                  <ul className="project-teaser__list">
                    {person.achievements.map(bullet => (
                      <li key={bullet}>{bullet}</li>
                    ))}
                  </ul>
                  {/* <Button type="button" onClick={() => setIsModalOpen(true)}>
                    Show Modal
                  </Button> */}
                </StyledPersonCard>
              </>
            )}
          >
            {/* Modal Content */}
            <h2>{person.name}</h2>
            <blockquote className="blockquote--decor mt-4">
              <p>»{person.quote}«</p>
            </blockquote>
            <BlockContent
              blocks={person._rawDetails}
              serializers={serializers}
            />
            <h3>Schwerpunkte</h3>
            <ul className="taglist">
              {person.tags.map(bullet => (
                <li key={bullet}>#{bullet}</li>
              ))}
            </ul>
          </Modal>
        </div>
      ))}
    </>
  );
}
