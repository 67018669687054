import React from 'react';
import { graphql } from 'gatsby';
import EqGrid from '../layout/Grid/EqGrid';
import IntroSection from '../components/IntroSection';
import GridColumn from '../layout/Grid/GridColumn';
import GridContainer from '../layout/Grid/GridContainer';
import GridRow from '../layout/Grid/GridRow';
import Section from '../layout/Section';
import Hero from '../components/Hero';
import SEO from '../components/SEO';
import PersonCard from '../components/PersonCard';
import EcosystemList from '../components/EcosystemList';
import Button from '../components/Button';
// import PeopleFieldsFragment from '../utils/fragments';

export default function WirPage({ data }) {
  // const categories = data.categories.edges;
  // console.log(categories);
  return (
    <>
      <SEO title={data.page.seoTitle} description={data.page.seoDescription} />

      <Hero hero={data.page} />

      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <IntroSection intro={data.page} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section color="subtle">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h2>Team</h2>
              <EqGrid xs="1" sm="2" md="3">
                {/* {data.categories.nodes.map(person => (
                  <>
                    <h3>{person.category}</h3>
                    <h3>{person.sort.name}</h3>
                  </>
                ))} */}
                <PersonCard people={data.people.teamSort} />
              </EqGrid>
              {/* <ToggleContent data={personCardData}>
								That's what we like to toggle.
								</ToggleContent> */}
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section color="subtle05">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h2>Beirat</h2>
              <EqGrid xs="1" sm="2" md="3">
                <PersonCard people={data.people.beiratSort} />
              </EqGrid>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h2>Ökosystempartner-Auswahl</h2>
              <EqGrid xs="1" sm="2" md="3">
                <EcosystemList partners={data.partners.ecosystemSort} />
              </EqGrid>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section color="subtle">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h3 className="mt-0">Sie möchten uns näher kennenlernen?</h3>
              <p>Wir freuen uns über Ihre Anfrage.</p>
              <Button to="/kontakt/" className="secondary">
                Kontakt
              </Button>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}

export const query = graphql`
  query wirPageQuery {
    people: sanityTeamEcosystemSort {
      teamSort {
        _rawDetails
        name
        quote
        tags
        id
        achievements
        category {
          category
        }
        photo {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
      }

      beiratSort {
        _rawDetails
        name
        quote
        tags
        id
        achievements
        category {
          category
        }
        photo {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
      }
    }

    partners: sanityTeamEcosystemSort {
      ecosystemSort {
        name
        keyfacts
        link
        category {
          category
        }
        logo {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    page: sanityMainPages(slug: { current: { eq: "wir" } }) {
      headline
      seoTitle
      seoDescription
      _rawIntroCopy
      heroHeadline
      enableHero
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    categories: allSanityPersonCategory {
      nodes {
        category
        sort {
          name
        }
      }
    }
  }
`;
