import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledEcosystemList = styled.a`
  text-decoration: none;
  font-weight: 400;
  border-bottom: none;
  /* background-color: var(--light-gray);
  padding: 1rem;
  border-radius: 15px; */
  /* border: 1px solid red; */
  /* width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  > div {
    margin-top: auto;
    height: 100%;
  } */

  h3 {
    margin-top: auto;
  }

  h4 {
    font-weight: 400;
    background-color: var(--light-gray);
    display: inline-block;
    /* color: white; */
    padding: 0.125em 0.5em;
    border-radius: 5px;
    margin-bottom: 0.5rem;
  }

  .project-teaser__list {
    padding-left: 1em;
    font-family: var(--sans-serif);
    font-size: var(--font-button);
    line-height: 1.2;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export default function EcosystemList({ partners }) {
  return (
    <>
      {partners.map(partner => (
        <StyledEcosystemList
          href={partner.link}
          key={partner.name}
          target="_blank"
          rel="noreferrer"
        >
          <Img
            className=""
            fluid={partner.logo.asset.fluid}
            // style={maxWidth: "100%"}
            durationFadeIn={400}
            alt=""
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'center center',
              marginTop: 'auto',
            }}
            style={{
              height: '120px',
              width: '75%',
            }}
          />
          {partner.category && <h4>{partner.category.category}</h4>}
          <h3>{partner.name}</h3>
          <ul className="project-teaser__list">
            {partner.keyfacts.map(bullet => (
              <li key={bullet}>{bullet}</li>
            ))}
          </ul>
        </StyledEcosystemList>
      ))}
    </>
  );
}
