import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
// import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { IconClose } from '../components/Icons';
import { above } from '../styles/MediaQueries';

const circleAnimation = keyframes`
  0% { clip-path: circle(75%); opacity: 1;}
  100% { clip-path: circle(0%); opacity: 0; }
`;

const StyledModalOuter = styled.div`
  display: grid;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  /* clip-path: circle(0); */
  ${above.medium`
		padding: 4rem;
	`}
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  /* transition: all 0.2s cubic-bezier(0.76, -0.245, 0.24, 1.245); */
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;

  &.is-open {
    /* opacity: 1; */
    animation: 0.5s ${circleAnimation} cubic-bezier(0.76, -0.245, 0.24, 1.245)
      reverse;
    animation-fill-mode: forwards;
    pointer-events: all;
    > div,
    > div:before,
    > div:after {
      transform: scale(1);
      opacity: 1;
    }
  }
  &.is-closed {
    /* animation: 0.4s ${circleAnimation} cubic-bezier(0.76, -0.245, 0.24, 1.245); */
    /* animation-fill-mode: forwards; */
  }
`;

const StyledModalInner = styled.div`
  /* width: 100vw;
  height: 100vh; */
  max-width: 720px;
  min-height: 100vh;
  /* min-width: 350px; */
  /* min-height: 200px; */
  background-color: transparent;
  padding: 4rem 2rem 6rem 2rem;
  ${above.medium`
		padding: 3rem;
	`}
  overflow-y: auto;
  position: relative;
  ${above.medium`
		// border-radius: 15px;
	`}
  /* border-radius: 20px; */
  /* transition: all 0.2s cubic-bezier(0.76, -0.245, 0.24, 1.245);
  transform: translateY(-40px); */

  .close-button {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 1em;
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 0.66;
    }
  }

  h3 {
    margin-top: 3rem;
  }

  .taglist {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 1rem 0;
    & > * {
      background-color: var(--light-gray);
      padding: 0.25em 1em;
      margin: 0.75rem 0 0;
      border-radius: 25px;
      font-family: var(--sans-serif);
      &:not(:last-child) {
        margin-right: 0.75rem;
      }
    }
  }
`;

const StyledCircleEffect = styled.div`
  position: relative;
  z-index: 0;
  transition: all 0.4s cubic-bezier(0.76, -0.245, 0.24, 1.245);
  /* transform: translateY(-40px); */
  &:before {
    transition: all 0.5s cubic-bezier(0.76, -0.245, 0.24, 1.245);
    transition-delay: 0.4s;
    opacity: 0;
    transform: scale(0.5);
    position: absolute;
    top: -25%;
    left: -50%;
    width: 660px;
    height: 660px;
    background-color: var(--moonlight-10);
    border-radius: 50%;
    content: '';
    z-index: -1;
  }
`;

// https://www.nicknish.co/blog/react-modal-with-usestate-and-portals

export default function Modal({ children, activator }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsModalOpen(false));

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  }

  // close on esc
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'Escape' && isModalOpen) {
        setIsModalOpen(false);
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    // https://www.pluralsight.com/guides/how-to-cleanup-event-listeners-react
    return function cleanUpListener() {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  // https://usehooks.com/useLockBodyScroll/
  // TODO: Improve so eefect only run at mount and unmount
  // useLayoutEffect vs useEffect
  function useLockBodyScroll() {
    useLayoutEffect(() => {
      // prevent body from jumping to left due to scrollbar
      const timer = setTimeout(() => {
        if (isModalOpen) {
          document.body.style.overflow = 'hidden';
        }
      }, 500);
      return () => {
        clearTimeout(timer);
        document.body.style.overflow = 'auto';
      };
    });
  }

  useLockBodyScroll();

  const modalContent = (
    <StyledModalOuter className={isModalOpen ? 'is-open' : 'is-closed'}>
      <StyledCircleEffect>
        <StyledModalInner ref={ref}>
          <button
            className="close-button"
            onClick={() => setIsModalOpen(false)}
          >
            <IconClose size="1.5" />
          </button>
          {children}
          <a className="inline-link mt-2" onClick={() => setIsModalOpen(false)}>
            Schließen
          </a>
        </StyledModalInner>
      </StyledCircleEffect>
    </StyledModalOuter>
  );
  return (
    <>
      {activator({ setIsModalOpen })}
      {/* {createPortal(modalContent, document.body)} */}
      {modalContent}
    </>
  );
}
